import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  TextField,
  Typography,
  Grid,
  Box,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Button from '../../../Button/Button.component';

import { useStyles } from './PartnerPasswordForm.styles';

const PartnerPasswordForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const { handleSubmit, control, watch } = useForm();

  const passwordInput = watch('password');

  const classes = useStyles();

  const onSubmitPasswordHandler = data => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPasswordHandler)}>
      <Typography variant="h4" className={classes.title}>
        Contraseña
      </Typography>
      {/* --- Contraseña --- */}
      <Grid container spacing={4}>
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type={isPasswordVisible ? 'text' : 'password'}
                label="Contraseña"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Elige una contraseña para ingresar a Wellnub'
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setIsPasswordVisible(prevState => !prevState)
                      }
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {/* --- Contraseña confirmacion --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="confirmPassword"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
              validate: value =>
                value === passwordInput || 'Las contraseñas no coinciden',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type={isConfirmVisible ? 'text' : 'password'}
                label="Confirma contraseña"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Confirma tu contraseña'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setIsConfirmVisible(prevState => !prevState)
                      }
                    >
                      {isConfirmVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box pt={4} textAlign="right">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          loading={false}
        >
          Actualizar contraseña
        </Button>
      </Box>
    </form>
  );
};

export default PartnerPasswordForm;
