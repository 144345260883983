import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';

import ClientRow from '../../Rows/Client/ClientRow.component';

import { useStyles } from './ClientTable.styles';

const ClientTable = ({ clients, variant }) => {
  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Check In{isExtraSmallSize ? ' / Tipo' : null}
            </TableCell>
            {!isExtraSmallSize && (
              <TableCell align="center" className={classes.cellHeader}>
                Tipo de cliente
              </TableCell>
            )}
            <TableCell align="center" className={classes.cellHeader}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Número de teléfono
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Horario
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map(client => (
            <ClientRow client={client} status={variant} key={client._id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientTable;
