import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tabs: {
    borderRight: `1px solid #c2c2c2`,
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
    },
  },
  tab: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.primary.light,
  },
}));
