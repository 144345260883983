import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

export const withProtected = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => state.authentication.isAuthenticated,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const withRedirect = connectedRouterRedirect({
  redirectPath: '/dashboard',
  allowRedirectBack: false,
  authenticatedSelector: state => !state.authentication.isAuthenticated,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
