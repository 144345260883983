import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  WellnubTextInput,
  WellnubNumberInput,
  WellnubCountryCodeInput,
} from '@wellnub/web-common';
import {
  useCheckInFitPassClient,
  usePreregisterFitPassClient,
} from 'hooks/client.hook';
import { setFitPassData } from 'redux/slices/client.slice';

const AddFitpassClientPanel = () => {
  const { fitpass } = useSelector(state => state.client);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: fitpass?.data.name ?? '',
      lastName: fitpass?.data.lastName ?? '',
      email: fitpass?.data.email ?? '',
      phoneNumber: fitpass?.data.phoneNumber ?? '',
      countryCode: fitpass?.data.countryCode ?? '',
    },
  });

  const { mutate: checkIn, isLoading } = useCheckInFitPassClient();

  const { mutate: preregister, isLoading: isLoadingPreregister } =
    usePreregisterFitPassClient();

  const dispatch = useDispatch();

  const onSubmitHandler = data => {
    if (fitpass.data._id && fitpass?.mode !== 'backup') {
      checkIn(fitpass.data._id);
      dispatch(
        setFitPassData({
          ...fitpass,
          variant: 'SUCCESS',
        }),
      );
    } else {
      preregister({ ...data });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Typography>
        {`${fitpass.mode !== '' ? 'Confirma' : 'Ingresa'}`} los datos del
        cliente{' '}
        {`${
          fitpass.mode === ''
            ? ', mandaremos un mensaje de confirmación al celular'
            : ''
        }`}
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            disabled={fitpass.mode === 'read' || fitpass.mode === 'backup'}
            fullWidth
            variant="outlined"
            name="name"
            helperText="Nombre"
            label="Nombre"
            rules={{
              required: 'Este campo es obligatorio',
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            disabled={fitpass.mode === 'read' || fitpass.mode === 'backup'}
            fullWidth
            variant="outlined"
            name="lastName"
            helperText="Apellidos"
            label="Apellidos"
            rules={{
              required: 'Este campo es obligatorio',
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <WellnubTextInput
            disabled={fitpass.mode === 'read' || fitpass.mode === 'backup'}
            fullWidth
            variant="outlined"
            name="email"
            helperText="Correo"
            label="Email"
            rules={
              fitpass.mode === 'read'
                ? {
                    required: 'Este campo es obligatorio',
                  }
                : {
                    required: 'Este campo es obligatorio',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Escribe un correo válido',
                    },
                  }
            }
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <WellnubCountryCodeInput
                label="Región"
                disabled={fitpass.mode !== 'backup'}
                control={control}
                name="countryCode"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <WellnubNumberInput
                fullWidth
                disabled={fitpass.mode !== 'backup'}
                variant="outlined"
                label="Celular"
                name="phoneNumber"
                defaultValue={fitpass?.data.phoneNumber ?? ''}
                control={control}
                format="## #### ####"
                rules={{
                  required: 'Este campo es requerido',
                  pattern: {
                    value: /[0-9]{10}/,
                    message: 'Escribe un número válido',
                  },
                }}
              />
            </Grid>
          </Grid>
          {/*<Controller*/}
          {/*  control={control}*/}
          {/*  defaultValue={fitpass?.data.phoneNumber ?? ''}*/}
          {/*  name="phoneNumber"*/}
          {/*  rules={{*/}
          {/*    validate: value => validatePhoneNumber(value),*/}
          {/*  }}*/}
          {/*  render={({ field: { value, onChange }, fieldState: { error } }) => (*/}
          {/*    <MUIPhoneInput*/}
          {/*      value={value}*/}
          {/*      fullWidth*/}
          {/*      disabled={fitpass.mode !== 'backup'}*/}
          {/*      variant="outlined"*/}
          {/*      error={!!error}*/}
          {/*      label="Celular *"*/}
          {/*      defaultCountry="mx"*/}
          {/*      onChange={onChange}*/}
          {/*      helperText={*/}
          {/*        error?.message ?? 'Celular de contacto a 10 dígitos'*/}
          {/*      }*/}
          {/*      onlyCountries={['mx', 'us']}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
        </Grid>
      </Grid>
      <Box textAlign="center" mt={2}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isLoading || isLoadingPreregister}
        >
          Confirmar
        </Button>
      </Box>
    </form>
  );
};

export default AddFitpassClientPanel;
