import { batch, useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setFitPassData } from 'redux/slices/client.slice';
import {
  useCheckInFitPassClient,
  useConfirmPreregisterSMS,
} from 'hooks/client.hook';

const ConfirmFitpassSMSPanel = () => {
  const { fitpass } = useSelector(state => state.client);

  const { mutateAsync: confirmSMS, isLoading } = useConfirmPreregisterSMS();

  const { mutate: checkIn, isLoading: isLoadingCheckin } =
    useCheckInFitPassClient();

  const dispatch = useDispatch();

  const onConfirmSMSHandler = () => {
    dispatch(
      setFitPassData({
        ...fitpass,
        variant: 'SUCCESS',
      }),
    );
    setTimeout(() => {
      confirmSMS({
        id: fitpass.data._id,
        data: {
          didGetSMS: true,
        },
      }).then(data => {
        checkIn(data._id);
      });
    }, 500);
  };

  const onDenySMSHandler = () => {
    dispatch(
      setFitPassData({
        ...fitpass,
        variant: 'ERROR',
      }),
    );
    setTimeout(() => {
      confirmSMS({
        id: fitpass.data._id,
        data: {
          didGetSMS: false,
        },
      }).then(data => {
        checkIn(data._id);
      });
    }, 500);
  };

  const onEditEmailHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          isActive: false,
          name: 'FITPASS_SMS_CONFIRMATION',
        }),
      );
      dispatch(
        setFitPassData({
          mode: 'backup',
          data: {
            ...fitpass.data,
          },
        }),
      );
    });
    setTimeout(() => {
      dispatch(
        setModalStatus({
          isActive: true,
          name: 'FITPASS_CLIENT_DATA',
        }),
      );
    }, 500);
  };

  return (
    <Box textAlign="center">
      <Typography variant="h5">
        Enviamos un mensaje al celular del cliente: {fitpass.data.phoneNumber}
      </Typography>
      <br />
      <Typography>
        Confirma con el cliente que recibió el mensaje para continuar. <br />
        Esto puede llegar a tardar hasta 5 minutos.
      </Typography>
      <Box
        my={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onDenySMSHandler}
          disabled={isLoading || isLoadingCheckin}
        >
          No recibió mensaje
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmSMSHandler}
          disabled={isLoading || isLoadingCheckin}
        >
          Sí recibió mensaje
        </Button>
      </Box>
      <Typography
        variant="body2"
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
          color: '#377990',
        }}
        onClick={onEditEmailHandler}
      >
        Editar celular
      </Typography>
    </Box>
  );
};

export default ConfirmFitpassSMSPanel;
