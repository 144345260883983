import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cards: [],
  order: {},
  target: {},
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    addCard: (state, action) => {
      state.cards.push(action.payload);
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
  },
});

export const { setCards, setOrder, addCard, setTarget } = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;
