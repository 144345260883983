import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Container, Grid, Typography, Link } from '@material-ui/core';

import { useStyles } from './Footer.styles';

const Footer = (_, ref) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer} ref={ref}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item md={4} sm={4} xs={12}>
            <Typography
              variant="body2"
              color="secondary"
              className={classes.info}
            >
              Copyright &reg; Wellnub {new Date().getFullYear()}
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Link
              to="/conditions"
              target="_blank"
              underline="none"
              component={RouterLink}
              className={classes.info}
            >
              Términos y Condiciones
            </Link>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Link
              to="/privacy"
              target="_blank"
              underline="none"
              component={RouterLink}
              className={classes.info}
            >
              Aviso de privacidad de datos
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default forwardRef(Footer);
