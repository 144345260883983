import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useVerifyFitPassPhoneNumber } from 'hooks/client.hook';
import { setFitPassData } from 'redux/slices/client.slice';
import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

const SearchFitpassClientPanel = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber: '',
      countryCode: '',
    },
  });

  const dispatch = useDispatch();

  const { mutate: verify, isLoading } = useVerifyFitPassPhoneNumber();

  const onSubmitHandler = data => {
    verify({ phoneNumber: data.phoneNumber });
    dispatch(
      setFitPassData({
        mode: '',
        data: {
          ...data,
          phoneNumber: data.phoneNumber,
          countryCode: data.countryCode,
        },
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Typography>
        Por favor ingresa el celular del cliente que deseas agregar
      </Typography>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6}>
          <WellnubCountryCodeInput
            label="Región"
            control={control}
            name="countryCode"
            rules={{ required: 'Este campo es requerido' }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <WellnubNumberInput
            fullWidth
            variant="outlined"
            label="Teléfono"
            name="phoneNumber"
            control={control}
            format="## #### ####"
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /[0-9]{10}/,
                message: 'Escribe un número válido',
              },
            }}
          />
        </Grid>
      </Grid>
      {/*<Controller*/}
      {/*  control={control}*/}
      {/*  name="phoneNumber"*/}
      {/*  rules={{*/}
      {/*    validate: value => validatePhoneNumber(value),*/}
      {/*  }}*/}
      {/*  render={({ field: { value, onChange }, fieldState: { error } }) => (*/}
      {/*    <MUIPhoneInput*/}
      {/*      value={value}*/}
      {/*      fullWidth*/}
      {/*      variant="outlined"*/}
      {/*      error={!!error}*/}
      {/*      label="Celular *"*/}
      {/*      defaultCountry="mx"*/}
      {/*      onChange={onChange}*/}
      {/*      helperText={error?.message ?? 'Celular de contacto a 10 dígitos'}*/}
      {/*      onlyCountries={['mx', 'us']}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
      <Box textAlign="center" mt={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </form>
  );
};

export default SearchFitpassClientPanel;
