import { configureStore } from '@reduxjs/toolkit';

import { authenticationReducer } from './slices/authentication.slice';
import { modalReducer } from './slices/modal.slice';
import { userReducer } from './slices/user.slice';
import { clientReducer } from './slices/client.slice';
import { paymentsReducer } from './slices/payments.slice';
import { drawersReducer } from './slices/drawers.slice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    modal: modalReducer,
    user: userReducer,
    client: clientReducer,
    payments: paymentsReducer,
    drawers: drawersReducer,
  },
});
