import { batch, useDispatch, useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  cancelCheckIn,
  getCheckList,
  getClient,
  getClients,
  getMonthlyPasses,
  getPasses,
  requestAddPass,
  requestAggregation,
  requestCheckIn,
  requestCheckInFitPassClient,
  requestConfirmPreregisterSMS,
  requestEdit,
  requestLink,
  requestPreregisterFitPassClient,
  requestUnlink,
  requestVerification,
  requestVerifyFitPassPhoneNumber,
} from 'network/services/client.service';

import { setModalStatus } from 'redux/slices/modal.slice';
import {
  setClientData,
  setClientRegisterStatus,
  setFitPassData,
} from 'redux/slices/client.slice';

// ---- TRAE LA INFORMACIÓN DE UN CLIENTE ----
export const useClient = id =>
  useQuery(['GET_CLIENT', id], () => getClient(id));

// ---- TRAE LA LISTA DE CLIENTES ----
export const useClients = () => useQuery('GET_CLIENTS', getClients);

// ---- TRAE LISTA DE CHECK IN ----
export const useCheckInList = () => useQuery('GET_CHECK_IN_LIST', getCheckList);

// ---- TRAE LOS PASES DE ESCANEO CORPORAL ----
export const usePasses = () => useQuery('GET_PASSES', getPasses);

// ---- TRAE CANTIDAD DE PASES USADOS POR MES ----
export const useMonthlyPasses = payload =>
  useQuery('GET_MONTHLY_PASSES', () => getMonthlyPasses(payload));

// ---- SOLICITA AGREGAR UN PASE DE ESCANEO CORPORAL A UN CLIENTE ----
export const useInbodyPasses = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestAddPass, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CLIENTS');
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar('Se agregó correctamente el pase a tu cliente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo agregar el pase. Intente más tarde');
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ADD_INBODY_PASSES',
          isActive: false,
        }),
      );
    },
  });
};

// ---- SOLICITA EL CHECK IN DEL CLIENTE ----
export const useRequestCheckIn = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestCheckIn, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CHECK_IN_LIST');
      queryClient.invalidateQueries('GET_MONTHLY_PASSES');
      enqueueSnackbar('Se realizó correctamente el envío', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal. Intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- CANCELA EL CHECK IN DEL CLIENTE ----
export const useCancelCheckIn = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(cancelCheckIn, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CHECK_IN_LIST');
      queryClient.invalidateQueries('GET_MONTHLY_PASSES');
      enqueueSnackbar('El envío se canceló exitosamente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal. Intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- VINCULA A UN CLIENTE ----
export const useLinkClient = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestLink, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CLIENTS');
      enqueueSnackbar('Se agregó correctamente el cliente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo agregar el cliente. Intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CLIENT_AGGREGATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- DESVINCULA AL CLIENTE ----
export const useUnlink = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestUnlink, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CLIENTS');
      enqueueSnackbar('Se desvinculó correctamente el cliente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo desvincular el cliente. Intente más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- VERIFICA QUE UN CLIENTE ESTE REGISTRADO O NO ----
export const useVerification = () => {
  const dispatch = useDispatch();

  return useMutation(requestVerification, {
    onSuccess: ({ data }) => {
      dispatch(setClientData(data));
      dispatch(setClientRegisterStatus(true));
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'CLIENT_AGGREGATION',
            isActive: true,
          }),
        );
      }, 500);
    },
    onError: () => {
      dispatch(setClientData({}));
      dispatch(setClientRegisterStatus(false));
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'CLIENT_AGGREGATION',
            isActive: true,
          }),
        );
      }, 500);
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CLIENT_VERIFICATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- AGREGA UN NUEVO CLIENTE ----
export const useAgregation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestAggregation, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CLIENTS');
      enqueueSnackbar('Se creó correctamente el cliente', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('No se pudo agregar el cliente. Intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CLIENT_AGGREGATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ACTUALIZA LA INFORMACIÓN DE UN CLIENTE ----
export const useEdition = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestEdit, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CLIENT');
      enqueueSnackbar('Se actualizó la información con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo completar la actualización. Intenta más tarde',
        {
          variant: 'error',
        },
      );
    },
  });
};

// ---- VERIFICA SI EXISTE UN CLIENTE FITPASS POR NUMERO DE TELÉFONO ----
export const useVerifyFitPassPhoneNumber = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  return useMutation(requestVerifyFitPassPhoneNumber, {
    onSuccess: data => {
      batch(() => {
        dispatch(setFitPassData({ mode: 'read', data }));
        dispatch(
          setModalStatus({
            isActive: false,
            name: 'SEARCH_FITPASS_CLIENT',
          }),
        );
      });
      setTimeout(() => {
        dispatch(
          setModalStatus({
            isActive: true,
            name: 'FITPASS_CLIENT_DATA',
          }),
        );
      }, 500);
    },
    onError: ({ response }) => {
      if (response.status === 404) {
        dispatch(
          setModalStatus({
            isActive: false,
            name: 'SEARCH_FITPASS_CLIENT',
          }),
        );
        setTimeout(() => {
          dispatch(
            setModalStatus({
              isActive: true,
              name: 'FITPASS_CLIENT_DATA',
            }),
          );
        }, 500);
      } else {
        enqueueSnackbar('Algo salió mal, intenta más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- PREREGISTRA A UN CLIENTE FITPASS ----
export const usePreregisterFitPassClient = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestPreregisterFitPassClient, {
    onSuccess: data => {
      console.log('Success al preregistrar un cliente', data);
      batch(() => {
        dispatch(
          setFitPassData({
            mode: '',
            data: {
              _id: data?._id,
              name: data?.name,
              lastName: data?.lastName,
              email: data?.email,
              phoneNumber: data?.phoneNumber,
            },
          }),
        );
        dispatch(
          setModalStatus({
            isActive: false,
            name: 'FITPASS_CLIENT_DATA',
          }),
        );
        setTimeout(() => {
          dispatch(
            setModalStatus({
              isActive: true,
              name: 'FITPASS_SMS_CONFIRMATION',
            }),
          );
        }, 500);
      });
    },
    onError: error => {
      console.dir(error);
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- REALIZA LA CONFIRMACIÓN DEL RECIBIMIENTO DEL SMS ----
export const useConfirmPreregisterSMS = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestConfirmPreregisterSMS, {
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- REALIZA UN CHECKIN DE UN CLIENTE FITPASS ----
export const useCheckInFitPassClient = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestCheckInFitPassClient, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CHECK_IN_LIST');
      enqueueSnackbar('Se realizó con éxito el check in', {
        variant: 'success',
      });
      setTimeout(() => {
        dispatch(
          setModalStatus({
            name: 'FITPASS_CLIENT_CHECKIN_SUCCESS',
            isActive: true,
          }),
        );
      }, 500);
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {},
  });
};
