import { Controller, useForm } from 'react-hook-form';

import { Typography, TextField, Box } from '@material-ui/core';

import { useResetPassword } from 'hooks/authentication.hook';

import Button from '../../Button/Button.component';

import { useStyles } from './ResetPasswordForm.styles';

const ResetPasswordForm = () => {
  const { mutate, isLoading } = useResetPassword();
  const { control, handleSubmit } = useForm();

  const onSubmitHandler = data => {
    mutate(data);
  };

  const classes = useStyles();

  return (
    <>
      <Typography>
        Ingrese el email con el que se registró, si es encontrado en nuestro
        registro se le enviará una contraseña temporal.
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
        <Controller
          name="email"
          defaultValue=""
          control={control}
          rules={{
            required: 'Este campo es requerido',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Escribe un correo válido',
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="email"
              variant="outlined"
              error={!!error}
              value={value}
              onChange={onChange}
              helperText={error ? error.message : 'Correo para iniciar sesión'}
            />
          )}
        />
        <Box pt={2} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Enviar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ResetPasswordForm;
