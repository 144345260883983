import { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isEmpty } from 'lodash';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  WellnubTable,
  WellnubTimeInput,
  WellnubDateInput,
} from '@wellnub/web-common';

import Button from '../../Button/Button.component';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setScheduleTimeId } from 'redux/slices/client.slice';

import { useUpdate } from 'hooks/user.hook';

const HEADERS = ['Acciones', 'Horario', 'Día'];

const DayOffScheduleTable = ({ dirtyHandler }) => {
  const [data, setData] = useState([]);
  const [isAllDaySelected, setIsAllDaySelected] = useState(false);

  const { closingDates } = useSelector(state => state.client);

  const { mutateAsync, isLoading } = useUpdate();

  const {
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      startTime: new Date(2022, 0, 1, 0, 0),
      endTime: new Date(2022, 0, 1, 23, 59),
      date: new Date(),
    },
  });

  const dispatch = useDispatch();

  const ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        batch(() => {
          dispatch(
            setModalStatus({
              isActive: true,
              name: 'DELETE_SCHEDULE_CLOSE_TIME',
            }),
          );
        });
        dispatch(setScheduleTimeId(id));
      },
    },
  ];

  const onAllDaySelectedChangeHandler = () => {
    setIsAllDaySelected(prevState => !prevState);
  };

  const onSaveHandler = () => {
    const { date, startTime, endTime } = getValues();
    const day = format(date, 'd');
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    const start = new Date(
      year,
      month,
      day,
      isAllDaySelected
        ? new Date(2022, 0, 1, 0, 0).getHours()
        : startTime.getHours(),
      isAllDaySelected
        ? new Date(2022, 0, 1, 0, 0).getMinutes()
        : startTime.getMinutes(),
    );
    const end = new Date(
      year,
      month,
      day,
      isAllDaySelected
        ? new Date(2022, 0, 1, 23, 59).getHours()
        : endTime.getHours(),
      isAllDaySelected
        ? new Date(2022, 0, 1, 23, 59).getMinutes()
        : endTime.getMinutes(),
    );
    mutateAsync({
      closingDates: [
        ...closingDates.map(date => date.data),
        {
          startDate: start,
          endDate: end,
        },
      ],
    })
      .then(() => {
        reset();
      })
      .catch(e => {});
  };

  useEffect(() => {
    if (!isEmpty(closingDates)) {
      const dates = closingDates.map(date => {
        const startTime = new Date(date.data.startDate);
        const endTime = new Date(date.data.endDate);
        return {
          ...date,
          data: {
            schedule: `${format(startTime, 'HH:mm')} - ${format(
              endTime,
              'HH:mm',
            )}`,
            date: `${format(startTime, 'PP', { locale: es })}`,
          },
        };
      });
      setData(dates);
    } else {
      setData([]);
    }
  }, [closingDates]);

  useEffect(() => {
    dirtyHandler(isDirty);
  }, [isDirty, dirtyHandler]);

  return (
    <Box>
      <WellnubTable headers={HEADERS} actions={ACTIONS} rows={data} />
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Inicio</Typography>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDaySelected}
            name="startTime"
            control={control}
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Fin</Typography>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDaySelected}
            name="endTime"
            control={control}
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Fecha</Typography>
          <WellnubDateInput
            fullWidth
            name="date"
            minDate={new Date()}
            maxDate={new Date(2023, 11)}
            control={control}
            inputVariant="outlined"
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
            loading={isLoading}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <Box>
        <FormControlLabel
          label="Ingresar todo el día"
          control={
            <Checkbox
              checked={isAllDaySelected}
              onChange={onAllDaySelectedChangeHandler}
              name="isAllWorkdaySelected"
              color="primary"
            />
          }
        />
      </Box>
    </Box>
  );
};

export default DayOffScheduleTable;
