import { wellnub } from '../wellnub.api';

export const getClient = id => wellnub.get(`/v1/partner/patient/${id}`);

export const getClients = () => wellnub.get('/v1/partner/patient');

export const getCheckList = () => wellnub.get('/v1/partner/inbodyPass');

export const getPasses = () => wellnub.get('/v1/partner/user/activePasses');

export const requestAddPass = id =>
  wellnub.post(`/v1/partner/patient/addPass/${id}`);

export const requestCheckIn = id =>
  wellnub.post(`/v1/partner/inbodyPass/usePass/${id}`);

export const cancelCheckIn = id =>
  wellnub.delete(`/v1/partner/inbodyPass/usePass/${id}`);

export const getMonthlyPasses = payload =>
  wellnub.get(
    `/v1/partner/inbodyPass/total?year=${payload.year}&month=${payload.month}`,
  );

export const requestUnlink = id =>
  wellnub.delete(`/v1/partner/patient/link/${id}`);

export const requestLink = id => wellnub.post(`/v1/partner/patient/link/${id}`);

export const requestVerification = credentials =>
  wellnub.post('/v1/partner/patient/verify', credentials);

export const requestAggregation = data =>
  wellnub.post('/v1/partner/patient', data);

export const requestEdit = payload =>
  wellnub.patch(`/v1/partner/patient/${payload.id}`, payload.data);

export const requestVerifyFitPassPhoneNumber = async payload => {
  const { data } = await wellnub.post(
    '/v1/partner/patient/verifyPrivate',
    payload,
  );
  return data;
};

export const requestPreregisterFitPassClient = async payload => {
  const { data } = await wellnub.post(
    '/v1/partner/patient/preregister',
    payload,
  );
  return data;
};

export const requestConfirmPreregisterSMS = async payload => {
  const { data } = await wellnub.patch(
    `/v1/partner/patient/preregister/${payload.id}`,
    payload.data,
  );
  return data;
};

export const requestCheckInFitPassClient = async id => {
  const { data } = await wellnub.post(
    `/v1/partner/patient/checkIn/fitpass/${id}`,
  );
  return data;
};
