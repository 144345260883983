import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  Link,
  Typography,
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';

import { setAuthentication } from 'redux/slices/authentication.slice';

import { useStyles } from './ProfileDropdown.styles';

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const classes = useStyles();

  const onOpenHandler = event => {
    setIsOpen(true);
    setAnchor(event.currentTarget);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onSignOutHandler = () => {
    localStorage.removeItem('authentication');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('status');
    dispatch(setAuthentication(false));
    replace('/');
  };

  return (
    <Box>
      <Avatar className={classes.avatar} onClick={onOpenHandler}>
        <AccountCircle color="secondary" />
      </Avatar>
      <Menu
        open={isOpen}
        anchorEl={anchor}
        onClose={onCloseHandler}
        onClick={onCloseHandler}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Link to="/settings" component={RouterLink} underline="none">
            <Typography variant="body2">Configuración</Typography>
          </Link>
        </MenuItem>
        <MenuItem onClick={onSignOutHandler}>
          <Typography variant="body2">Salir</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileDropdown;
