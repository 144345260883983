import { useLocation, Link, useRouteMatch } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { useStyles } from './ProfileNavigation.styles';

const ProfileNavigation = () => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('profile')) return 'profile';
    return 'profile';
  };

  return (
    <Tabs
      orientation="vertical"
      value={tabValueHandler()}
      variant="scrollable"
      className={classes.tabs}
      indicatorColor="primary"
    >
      <Tab
        component={Link}
        to={`${url}/profile`}
        label="Información general"
        value="profile"
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default ProfileNavigation;
