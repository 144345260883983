import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';

const SuccessFitpassClientCheckInPanel = () => {
  const { fitpass } = useSelector(state => state.client);

  const dispatch = useDispatch();

  const onAcceptClickHandler = () => {
    dispatch(
      setModalStatus({
        name: 'FITPASS_CLIENT_CHECKIN_SUCCESS',
        isActive: false,
      }),
    );
  };

  return (
    <Box textAlign="center">
      {fitpass.variant === 'SUCCESS' && (
        <>
          <Typography variant="h5">¡Listo!</Typography>
          <br />
          <Typography>
            Ya puedes llevar al cliente al equipo a hacerse su prueba
            InBody&reg;
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onAcceptClickHandler}
            >
              Aceptar
            </Button>
          </Box>
        </>
      )}
      {fitpass.variant === 'ERROR' && (
        <>
          <Typography variant="h5">Cliente no verificado</Typography>
          <br />
          <Typography>
            Wellnub se pondrá en contacto con el cliente en las próximas 48
            horas para confirmar sus datos
          </Typography>
          <br />
          <Typography>
            Ya puedes llevar al cliente a realizar su prueba
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onAcceptClickHandler}
            >
              Aceptar
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SuccessFitpassClientCheckInPanel;
