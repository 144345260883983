import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import {
  SignInPage,
  DashboardPage,
  ClientsPage,
  ClientPage,
  SettingsPage,
  InstructionsPage,
  PrivacyPage,
  ConditionsPage,
} from 'pages';

import { ModalManager, Header, Footer, DrawerManager } from 'components';

import { setUserStatus } from 'redux/slices/user.slice';
import { setAuthentication } from 'redux/slices/authentication.slice';

import { withProtected, withRedirect } from 'utils/authentication';

const useStyles = makeStyles(() => ({
  app: {
    minHeight: props =>
      `calc(100vh - ${props.footerHeight}px - ${props.headerHeight}px)`,
  },
}));

const App = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const dispatch = useDispatch();

  const classes = useStyles({ headerHeight, footerHeight });

  const isAuthenticated = JSON.parse(localStorage.getItem('authentication'));

  const measuredHeaderRef = useCallback(node => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const measuredFooterRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    const status = localStorage.getItem('status');
    dispatch(setUserStatus(status));
  }, [dispatch]);

  if (isAuthenticated) {
    dispatch(setAuthentication(true));
  }

  return (
    <>
      <DrawerManager />
      <ModalManager />
      <Header ref={measuredHeaderRef} />
      <Box component="main" className={classes.app}>
        <Switch>
          <Route exact path="/" component={withRedirect(SignInPage)} />
          <Route
            exact
            path="/dashboard"
            component={withProtected(DashboardPage)}
          />
          <Route exact path="/clients" component={withProtected(ClientsPage)} />
          <Route path="/clients/:id" component={withProtected(ClientPage)} />
          <Route path="/settings" component={withProtected(SettingsPage)} />
          <Route
            exact
            path="/instructions"
            component={withProtected(InstructionsPage)}
          />
          <Route exact path="/privacy">
            <PrivacyPage />
          </Route>
          <Route exact path="/conditions">
            <ConditionsPage />
          </Route>
        </Switch>
      </Box>
      <Footer ref={measuredFooterRef} />
    </>
  );
};

export default App;
