import { Box, Container, Typography } from '@material-ui/core';

import { WellnubPDFViewer } from '@wellnub/web-common';

import { useStyles } from './Conditions.styles';

const ConditionsPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Términos y Condiciones
      </Typography>
      <Box mt={4}>
        <WellnubPDFViewer url="https://wellnub.s3.us-west-1.amazonaws.com/legal/220203_wellnub_tc.pdf" />
      </Box>
    </Container>
  );
};

export default ConditionsPage;
