import { wellnub } from '../wellnub.api';

export const requestBundles = () =>
  wellnub.get('/v1/partner/storeProduct/coin');

export const requestPaymentApprovement = payload =>
  wellnub.post('/v1/partner/payment/buyCoins', payload);

export const requestAddCreditCard = payload =>
  wellnub.post('/v1/partner/payment/card', payload);

export const requestGetCreditCard = id =>
  wellnub.get(`/v1/partner/payment/card/${id}`);

export const requestGetCreditCards = () =>
  wellnub.get('/v1/partner/payment/card');

export const requestSetDefaultCard = id =>
  wellnub.patch(`/v1/partner/payment/card/defaultCard/${id}`);

export const requestDeleteCreditCard = id =>
  wellnub.delete(`/v1/partner/payment/card/${id}`);
