import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
    textTransform: 'uppercase',
    color: theme.palette.action.main,
  },
  paragraph: {
    paddingBottom: theme.spacing(3),
  },
  divider: {
    height: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.action.main,
  },
}));
