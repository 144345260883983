import { useSelector } from 'react-redux';

import WithModal from '../../HOC/WithModal/WithModal.component';

import ResetPasswordForm from '../../Forms/ResetPassword/ResetPasswordForm.component';
import InbodyPassForm from '../../Forms/InbodyPass/InbodyPassForm.component';
import ClientVerificationForm from '../../Forms/Client/Verification/ClientVerificationForm.component';
import ClientAggregationForm from '../../Forms/Client/Aggregation/ClientAggregationForm.component';
import SetDefaultCardForm from '../../Forms/Payments/SetDefaultCard/SetDefaultCardForm.component';
import DeleteCardForm from '../../Forms/Payments/DeleteCard/DeleteCardForm.component';

import DeleteScheduleCloseTime from '../Panels/DeleteScheduleCloseTime/DeleteScheduleCloseTime.component';
import DeleteScheduleOpenTime from '../Panels/DeleteScheduleOpenTime/DeleteScheduleOpenTime.component';
import SearchFitpassClientPanel from '../Panels/SearchFitpassClient/SearchFitpassClientPanel.component';
import AddFitpassClientPanel from '../Panels/AddFitpassClient/AddFitpassClientPanel.component';
import ConfirmFitpassSMSPanel from '../Panels/ConfirmFitpassSMS/ConfirmFitpassSMSPanel.component';
import SuccessFitpassClientCheckInPanel from '../Panels/SuccessFitpassClientCheckIn/SuccessFitpassClientCheckInPanel.component';

const ModalManager = () => {
  const { name, isActive } = useSelector(state => state.modal);
  const { fitpass } = useSelector(state => state.client);

  switch (name) {
    case 'PASSWORD_RESET':
      return (
        <WithModal
          open={isActive}
          name="PASSWORD_RESET"
          title="Recuperar contraseña"
          BodyComponent={<ResetPasswordForm />}
        />
      );
    case 'CLIENT_VERIFICATION':
      return (
        <WithModal
          open={isActive}
          name="CLIENT_VERIFICATION"
          title="Agregar cliente"
          BodyComponent={<ClientVerificationForm />}
        />
      );
    case 'CLIENT_AGGREGATION':
      return (
        <WithModal
          open={isActive}
          name="CLIENT_AGGREGATION"
          title="Agregar cliente"
          BodyComponent={<ClientAggregationForm />}
        />
      );
    case 'ADD_INBODY_PASSES':
      return (
        <WithModal
          open={isActive}
          name="ADD_INBODY_PASSES"
          title="Agregar Pases"
          BodyComponent={<InbodyPassForm />}
        />
      );
    case 'SET_DEFAULT_CARD':
      return (
        <WithModal
          open={isActive}
          name="SET_DEFAULT_CARD"
          title="Cambiar tarjeta principal"
          BodyComponent={<SetDefaultCardForm />}
        />
      );
    case 'DELETE_CARD':
      return (
        <WithModal
          open={isActive}
          name="DELETE_CARD"
          title="Eliminar tarjeta"
          BodyComponent={<DeleteCardForm />}
        />
      );
    case 'DELETE_SCHEDULE_OPEN_TIME':
      return (
        <WithModal
          open={isActive}
          name="DELETE_SCHEDULE_OPEN_TIME"
          title="Borrar entrada"
          BodyComponent={<DeleteScheduleOpenTime />}
        />
      );
    case 'DELETE_SCHEDULE_CLOSE_TIME':
      return (
        <WithModal
          open={isActive}
          name="DELETE_SCHEDULE_CLOSE_TIME"
          title="Borrar entrada"
          BodyComponent={<DeleteScheduleCloseTime />}
        />
      );
    case 'SEARCH_FITPASS_CLIENT':
      return (
        <WithModal
          open={isActive}
          name="SEARCH_FITPASS_CLIENT"
          title="Prueba cliente FitPass"
          BodyComponent={<SearchFitpassClientPanel />}
        />
      );
    case 'FITPASS_CLIENT_DATA':
      return (
        <WithModal
          open={isActive}
          name="FITPASS_CLIENT_DATA"
          title={`${fitpass.mode !== '' ? 'Confirmar' : 'Agregar'} cliente`}
          BodyComponent={<AddFitpassClientPanel />}
        />
      );
    case 'FITPASS_SMS_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="FITPASS_SMS_CONFIRMATION"
          title="Confirmar cliente"
          BodyComponent={<ConfirmFitpassSMSPanel />}
        />
      );
    case 'FITPASS_CLIENT_CHECKIN_SUCCESS':
      return (
        <WithModal
          open={isActive}
          name="FITPASS_CLIENT_CHECKIN_SUCCESS"
          title="Check in de cliente"
          BodyComponent={<SuccessFitpassClientCheckInPanel />}
        />
      );
    default:
      return null;
  }
};

export default ModalManager;
