import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import { AppBar, Tab, Tabs } from '@material-ui/core';

import { useStyles } from './SettingsNavigation.styles';

const SettingsNavigation = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('general')) return 'general';
    if (pathname.includes('payments')) return 'payments';
    return 'general';
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      classes={{ root: classes.appBar }}
    >
      <Tabs
        variant="fullWidth"
        value={tabValueHandler()}
        indicatorColor="primary"
      >
        <Tab
          value="general"
          component={Link}
          label="Mi Perfil"
          to={`${url}/general`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        <Tab
          value="payments"
          component={Link}
          label="Pagos"
          to={`${url}/payments`}
          classes={{
            wrapper: classes.tab,
          }}
        />
      </Tabs>
    </AppBar>
  );
};

export default SettingsNavigation;
