import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 500,
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  subtitle: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  center: {
    textAlign: 'center',
  },
  cardNumber: {
    paddingLeft: theme.spacing(1),
  },
  cardLogo: {
    maxWidth: 45,
  },
  asLink: {
    width: 'max-content',
    cursor: 'pointer',
    color: theme.palette.primary.dark,
  },
  conekta: {
    width: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
