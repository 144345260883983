import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';

import { useStyles } from './ClientNavigation.styles';

const ClientNavigation = ({ client }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { url } = useRouteMatch();

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const tabChangeHandler = (event, tabValue) => {
    setSelectedTab(tabValue);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      classes={{ root: classes.appBar }}
    >
      <Tabs
        variant="fullWidth"
        onChange={tabChangeHandler}
        value={selectedTab}
        indicatorColor="primary"
      >
        {!isExtraSmallSize && (
          <Tab
            disabled
            label={`${client.name} ${client.lastName}`}
            icon={<FaceIcon />}
          />
        )}
        <Tab
          value={0}
          component={Link}
          label="Progreso"
          to={`${url}/progress`}
          classes={{
            wrapper: classes.tab,
          }}
        />
        <Tab
          value={1}
          component={Link}
          label="Información"
          to={`${url}/information`}
          classes={{
            wrapper: classes.tab,
          }}
        />
      </Tabs>
    </AppBar>
  );
};

export default ClientNavigation;
