import { useSelector, useDispatch } from 'react-redux';

import { setVisibility } from 'redux/slices/drawers.slice';

export const useDrawer = key => {
  const { isVisible: visibility } = useSelector(state => state.drawers[key]);

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(
      setVisibility({
        key,
        status: true,
      }),
    );
  };

  const handleClose = () => {
    dispatch(
      setVisibility({
        key,
        status: false,
      }),
    );
  };

  return { visibility, handleClose, handleOpen };
};
