import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';

import { Typography, Grid, TextField, Box, Divider } from '@material-ui/core';

import Button from '../../../Button/Button.component';
import WorkdaysScheduleTable from '../../../Tables/WorkdaysSchedule/WorkdaysScheduleTable.component';
import DayOffScheduleTable from '../../../Tables/DayOffScheduleTable/DayOffScheduleTable.component';

import { useUpdate } from 'hooks/user.hook';

import { useStyles } from './PartnerProfileForm.styles';
import { isEmpty } from 'lodash';

const PartnerProfileForm = ({ data }) => {
  const [hasError, setHasError] = useState(false);
  const [isFirstTableDirty, setIsFirstTableDirty] = useState(false);
  const [isSecondTableDirty, setIsSecondTableDirty] = useState(false);

  const { mutate, isLoading } = useUpdate();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: data,
  });

  const classes = useStyles();

  const onSubmitHandler = fields => {
    const { inbodyScheduleTimes, closingDates, ...restData } = data;
    mutate({
      ...restData,
      ...fields,
      inbodyScheduleTimes,
      closingDates,
    });
  };

  useEffect(() => {
    setHasError(!isEmpty(data?.inbodyScheduleTimes));
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Prompt
        when={isDirty || isFirstTableDirty || isSecondTableDirty}
        message="Tienes cambios sin guardar, ¿estás seguro que deseas salir?"
      />
      <Typography variant="h4" className={classes.title}>
        Datos generales para clientes externos
      </Typography>

      <Grid container spacing={4}>
        {/* --- Nombre --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Nombre"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error ? error.message : 'Escribe el nombre de tu gym/estudio'
                }
              />
            )}
          />
        </Grid>
        {/* --- Email --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                disabled
                type="email"
                label="E-mail"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'user@example.com'}
              />
            )}
          />
        </Grid>
        {/* --- Telefono --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="phoneNumber"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Teléfono de sucursal"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : '(22) 2222-2222'}
              />
            )}
          />
        </Grid>
        {/* --- Sitio Web --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="website"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Sitio web"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error ? error.message : 'Sitio web o página en redes sociales'
                }
              />
            )}
          />
        </Grid>
        {/* --- Direccion 1 --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.fullAddress"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Dirección"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Calle, número, edificio, número interior'
                }
              />
            )}
          />
        </Grid>
        {/* --- Colonia --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.neighborhood"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Colonia"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Colonia'}
              />
            )}
          />
        </Grid>
        {/* --- Direccion 2 --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.state"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Dirección 2"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Estado'}
              />
            )}
          />
        </Grid>
        {/* --- Código postal --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.zipCode"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Código postal"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Introduce el código postal. Ejemplo, 03700 '
                }
              />
            )}
          />
        </Grid>
        {/* --- Enlace Google Maps--- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.mapUrl"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Link Google Maps"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Introduce el enlace de tu ubicación en Google Maps'
                }
              />
            )}
          />
        </Grid>
        {/* --- Indicaciones --- */}
        <Grid item xs={12}>
          <Controller
            name="indications"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                type="text"
                label="Otras indicaciones"
                value={value}
                error={!!error}
                variant="outlined"
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Breve descripción para facilitar el acceso/llegada al gym y al check-in de las pruebas de escaneo corporal (Ejemplo: direcciones específicas, piso del edificio, si deben preguntar en recepción, estacionamiento, parquímetro, etc.)'
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      {/* --- Inbody Asignado --- */}
      <Typography variant="h4" className={classes.title}>
        Inbody asignado
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="inbodyNumber"
            defaultValue=""
            control={control}
            render={({ field: { value } }) => (
              <TextField
                fullWidth
                disabled
                type="text"
                label="Número de serie"
                value={value}
                helperText="Número de serie del equipo InBody"
              />
            )}
          />
        </Grid>
      </Grid>
      <Box pt={4} textAlign="right">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          loading={isLoading}
        >
          Actualizar información
        </Button>
      </Box>
      <Typography variant="h4" className={classes.title}>
        Horario de atención para pruebas Inbody
      </Typography>
      <Typography className={classes.paragraph}>
        Por favor indica los días y horarios en que los clientes externos pueden
        acudir a realizarse escaneos corporales.
      </Typography>
      {/* --- Horarios de atencion --- */}
      <WorkdaysScheduleTable dirtyHandler={setIsFirstTableDirty} />
      <br />
      <Controller
        defaultValue=""
        control={control}
        rules={{
          validate: () => {
            return (
              hasError || 'Debes de agregar al menos un horario de atención'
            );
          },
        }}
        name="hiddenWorkDays"
        render={({ fieldState: { error } }) => (
          <TextField
            fullWidth
            type="hidden"
            error={!!error}
            helperText={error ? error.message : ' '}
          />
        )}
      />
      <br />
      <br />
      <br />
      <Typography variant="h4" className={classes.title}>
        Días sin servicio
      </Typography>
      <Typography className={classes.paragraph}>
        Por favor indica los días y horarios del año en los que no será posible
        que los clientes acudan a realizarse escaneos corporales.
      </Typography>

      <DayOffScheduleTable dirtyHandler={setIsSecondTableDirty} />
    </form>
  );
};

export default PartnerProfileForm;
