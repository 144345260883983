import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isAlreadyRegistered: false,
  placeholder: {
    email: '',
    phoneNumber: '',
    countryCode: '',
  },
  inbodyScheduleTimes: [],
  closingDates: [],
  scheduleTimeId: '',
  fitpass: {
    mode: '',
    data: {
      _id: '',
      name: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      countryCode: '',
    },
  },
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientData: (state, action) => {
      state.data = action.payload;
    },
    setClientRegisterStatus: (state, action) => {
      state.isAlreadyRegistered = action.payload;
    },
    setEmailPlaceholder: (state, action) => {
      state.placeholder.email = action.payload;
    },
    setPhoneNumberPlaceholder: (state, action) => {
      state.placeholder.phoneNumber = action.payload;
    },
    setCountryCodePlaceholder: (state, action) => {
      state.placeholder.countryCode = action.payload;
    },
    setScheduleTimeId: (state, action) => {
      state.scheduleTimeId = action.payload;
    },
    setInbodyScheduleTimes: (state, action) => {
      state.inbodyScheduleTimes = action.payload;
    },
    setClosingDates: (state, action) => {
      state.closingDates = action.payload;
    },
    setFitPassData: (state, action) => {
      state.fitpass = action.payload;
    },
  },
});

export const {
  setClientData,
  setClientRegisterStatus,
  setEmailPlaceholder,
  setPhoneNumberPlaceholder,
  setScheduleTimeId,
  setInbodyScheduleTimes,
  setClosingDates,
  setFitPassData,
  setCountryCodePlaceholder,
} = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
