import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  name: {
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.action.main,
  },
  passes: {
    textAlign: 'center',
    color: theme.palette.primary.light,
  },
  button: {
    fontSize: 12,
  },
  primary: {
    color: theme.palette.action.main,
  },
  secondary: {
    color: theme.palette.primary.light,
  },
  center: {
    textAlign: 'center',
  },
}));
