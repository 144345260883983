import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
  form: {
    paddingTop: theme.spacing(3),
  },
}));
