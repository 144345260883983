import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  requestAddCreditCard,
  requestBundles,
  requestGetCreditCards,
  requestPaymentApprovement,
  requestSetDefaultCard,
  requestDeleteCreditCard,
} from 'network/services/payments.service';

import { setCards } from 'redux/slices/payments.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { DRAWERS } from 'utils/constants';

import { useDrawer } from './drawers.hook';

// ---- AÑADE UNA TARJETA DE CRÉDITO NUEVA ----
export const useAddCreditCard = () => {
  const { handleClose } = useDrawer(DRAWERS.addCard);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestAddCreditCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Se agregó correctamente la tarjeta', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intente mas tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- TRAE EL LISTADO DE TARJETAS DE CRÉDITO ----
export const useGetCreditCards = () => {
  const dispatch = useDispatch();

  return useQuery('GET_CREDIT_CARDS', requestGetCreditCards, {
    onSuccess: ({ data }) => {
      dispatch(setCards(data));
    },
  });
};

// ---- TRAE LOS PAQUETES DE PASSES DISPONIBLES ----
export const useBundles = () => useQuery('GET_BUNDLES', requestBundles);

// ---- REALIZA LA COMPRA DE PASES ----
export const useBuyBundles = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.checkout);

  return useMutation(requestPaymentApprovement, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar('Se completó la compra con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la compra', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- ESTABLECE POR DEFECTO UNA TARJETA ----
export const useSetDefaultCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestSetDefaultCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Se cambió la forma de pago principal', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'SET_DEFAULT_CARD',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ELIMINA UNA TARJETA ----
export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestDeleteCreditCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Se eliminó correctamente la tarjeta', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_CARD',
          isActive: false,
        }),
      );
    },
  });
};
