import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AppBar,
  Box,
  CardMedia,
  Container,
  IconButton,
  Toolbar,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ProfileDropdown } from 'components';

import mainLogo from 'assets/images/logo-small.png';

import SideMenu from '../SideMenu/SideMenu.component';

import { useStyles } from './Header.styles';

const Header = (_, ref) => {
  const [sideMenuStatus, setSideMenuStatus] = useState(false);

  const { isAuthenticated } = useSelector(state => state.authentication);

  const classes = useStyles();

  const sideMenuStatusHandler = status => () => {
    setSideMenuStatus(status);
  };

  return (
    <AppBar position="static" ref={ref}>
      <Container maxWidth="xl">
        <SideMenu
          isOpen={sideMenuStatus}
          closeHandler={sideMenuStatusHandler(false)}
        />
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              edge="start"
              color="secondary"
              onClick={sideMenuStatusHandler(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <CardMedia
              component="img"
              image={mainLogo}
              className={classes.logo}
            />
            {isAuthenticated && <ProfileDropdown />}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default forwardRef(Header);
