import CheckoutDrawer from './Checkout/CheckoutDrawer.component';
import CreditCardDrawer from './CreditCard/CreditCardDrawer.component';

const DrawerManager = () => {
  return (
    <>
      <CheckoutDrawer />
      <CreditCardDrawer />
    </>
  );
};

export default DrawerManager;
