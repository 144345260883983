export const sideMenuLinks = [
  {
    url: '/dashboard',
    name: 'Home',
  },
  {
    url: '/clients',
    name: 'Mis clientes',
  },
  {
    url: '/instructions',
    name: 'Instrucciones',
  },
];

export const STATUSES = {
  active: {
    title: 'Activo',
    value: true,
  },
  inactive: {
    title: 'Inactivo',
    value: false,
  },
  'pending validation': {
    title: 'Pendiente de activar',
    value: false,
  },
};

export const YEARS = [
  { name: '2021', value: 2021 },
  { name: '2022', value: 2022 },
  { name: '2023', value: 2023 },
  { name: '2024', value: 2024 },
  { name: '2025', value: 2025 },
  { name: '2026', value: 2026 },
  { name: '2027', value: 2027 },
  { name: '2028', value: 2028 },
  { name: '2029', value: 2029 },
  { name: '2031', value: 2031 },
  { name: '2032', value: 2032 },
  { name: '2033', value: 2033 },
  { name: '2034', value: 2034 },
  { name: '2035', value: 2035 },
];

export const MONTHS = [
  { name: 'Enero', value: 1 },
  { name: 'Febrero', value: 2 },
  { name: 'Marzo', value: 3 },
  { name: 'Abril', value: 4 },
  { name: 'Mayo', value: 5 },
  { name: 'Junio', value: 6 },
  { name: 'Julio', value: 7 },
  { name: 'Agosto', value: 8 },
  { name: 'Septiembre', value: 9 },
  { name: 'Octubre', value: 10 },
  { name: 'Noviembre', value: 11 },
  { name: 'Diciembre', value: 12 },
];

export const CHECK_IN_STATUSSES = {
  internal: 'Interno',
  external: 'Externo',
  fitpass: 'FitPass',
};

export const DRAWERS = {
  addCard: 'addCard',
  checkout: 'checkout',
  signIn: 'signIn',
};

export const DAYS = {
  1: 'Lu',
  2: 'Ma',
  3: 'Mi',
  4: 'Ju',
  5: 'Vi',
  6: 'Sa',
  0: 'Do',
};

export const WEEK_OPTIONS = [
  { id: 'day-1', label: 'Lu', value: 1 },
  { id: 'day-2', label: 'Ma', value: 2 },
  { id: 'day-3', label: 'Mi', value: 3 },
  { id: 'day-4', label: 'Ju', value: 4 },
  { id: 'day-5', label: 'Vi', value: 5 },
  { id: 'day-6', label: 'Sa', value: 6 },
  { id: 'day-0', label: 'Do', value: 0 },
];