import { get } from 'lodash';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import {
  Box,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import Button from '../../Button/Button.component';

import { useCancelCheckIn, useRequestCheckIn } from 'hooks/client.hook';

import { CHECK_IN_STATUSSES } from 'utils/constants';

import { useStyles } from './ClientRow.styles';

const ClientRow = ({ client, status }) => {
  const { mutate: requestCheckIn, isLoading: isRequestLoading } =
    useRequestCheckIn();
  const { mutate: cancelCheckIn, isLoading: isCancelLoading } =
    useCancelCheckIn();

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const label = {
    active: 'Enviar resultados',
    inactive: 'Deshacer envío',
  }[status];

  const onClickHandler = id => event => {
    event.preventDefault();
    if (status === 'active') {
      requestCheckIn(id);
    }
    if (status === 'inactive') {
      cancelCheckIn(id);
    }
  };

  return (
    <TableRow key={client._id} className={classes.row}>
      <TableCell align="center">
        {(client.type === 'internal' || client.type === 'external') && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickHandler(client._id)}
            loading={isRequestLoading || isCancelLoading}
          >
            {label}
          </Button>
        )}
        {isExtraSmallSize && (
          <Box pt={1}>
            <Typography variant="body2">
              {CHECK_IN_STATUSSES[client.type]}
            </Typography>
            {client.type === 'external' && (
              <Typography variant="caption" className={classes.spec}>
                Prueba pagada
              </Typography>
            )}
          </Box>
        )}
      </TableCell>
      {!isExtraSmallSize && (
        <TableCell align="center">
          {CHECK_IN_STATUSSES[client.type]}
          <br />
          {client.type === 'external' && (
            <Typography variant="caption" className={classes.spec}>
              Prueba pagada
            </Typography>
          )}
        </TableCell>
      )}
      <TableCell align="center">
        <Typography variant="body2">
          {get(client, 'patient.name', ' ')}&nbsp;
          {get(client, 'patient.lastName', '')}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {client?.type === 'fitpass'
            ? `** **** ${client?.patient?.phoneNumber?.slice(-4)}`
            : `** **** ${client?.patient?.phoneNumber}`}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">
          {format(new Date(client?.scheduleDate ?? null), "EEEEEE',' PP',' p", {
            locale: es,
          })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ClientRow;
