import { useForm, Controller } from 'react-hook-form';

import { Grid, TextField, Box } from '@material-ui/core';

import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

import { useEdition } from 'hooks/client.hook';

import Button from '../../../Button/Button.component';

const ClientEditForm = ({ editable, data }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber: data?.phoneNumber ?? '',
      countryCode: data?.countryCode ?? '',
    },
  });

  const { mutate, isLoading } = useEdition();

  const onSubmitHandler = fields => {
    const { email, ...rest } = fields;
    mutate({
      id: data._id,
      data: { ...rest },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={4}>
        {/* --- Nombre --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="name"
            defaultValue={data.name}
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                disabled={!editable}
                type="text"
                label="Nombre"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        {/* --- Apellido --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="lastName"
            defaultValue={data.lastName}
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                disabled={!editable}
                type="text"
                label="Apellido"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        {/* --- Telefono --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <WellnubCountryCodeInput
                label="Región"
                disabled={!editable}
                control={control}
                name="countryCode"
                defaultValue={data?.countryCode ?? '52'}
                rules={{ required: 'Este campo es requerido' }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <WellnubNumberInput
                fullWidth
                disabled={!editable}
                variant="outlined"
                label="Celular *"
                name="phoneNumber"
                control={control}
                defaultValue={data?.phoneNumber ?? ''}
                rules={{
                  required: 'Este campo es obligatorio',
                  pattern: {
                    value: /[0-9]{10}/,
                    message: 'Escribe un número válido',
                  },
                }}
                format="## #### ####"
                helperText="Celular de contacto a 10 dígitos"
              />
            </Grid>
          </Grid>
          {/*<Controller*/}
          {/*  control={control}*/}
          {/*  name="phoneNumber"*/}
          {/*  defaultValue={data.phoneNumber}*/}
          {/*  rules={{*/}
          {/*    validate: value => validatePhoneNumber(value),*/}
          {/*  }}*/}
          {/*  render={({ field: { value, onChange }, fieldState: { error } }) => (*/}
          {/*    <MUIPhoneInput*/}
          {/*      value={value}*/}
          {/*      fullWidth*/}
          {/*      variant="outlined"*/}
          {/*      error={!!error}*/}
          {/*      label="Celular *"*/}
          {/*      defaultCountry="mx"*/}
          {/*      onChange={onChange}*/}
          {/*      disabled={!editable}*/}
          {/*      helperText={*/}
          {/*        error?.message ?? 'Celular de contacto a 10 dígitos'*/}
          {/*      }*/}
          {/*      onlyCountries={['mx', 'us']}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
        </Grid>
        {/* --- Correo --- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="email"
            defaultValue={data.email}
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                disabled
                type="email"
                label="Email"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box pt={4} textAlign="center">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!editable}
          loading={isLoading}
        >
          Actualizar
        </Button>
      </Box>
    </form>
  );
};

export default ClientEditForm;
