import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

import { wellnub } from 'network/wellnub.api';
import { signIn, resetPassword } from 'network/services/authentication.service';

import { setAuthentication } from 'redux/slices/authentication.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setUserStatus } from '../redux/slices/user.slice';

// ---- SOLICITAR INICIO DE SESIÓN ----
export const useSignIn = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(signIn, {
    onSuccess: ({ data }) => {
      wellnub.defaults.headers.common.Authorization = `Bearer ${data.token.accessToken}`;
      localStorage.setItem('accessToken', data.token.accessToken);
      localStorage.setItem('authentication', 'true');
      localStorage.setItem('status', data.user.status);
      localStorage.setItem('refreshToken', data.token.refreshToken);
      localStorage.setItem('email', data.user.email);
      dispatch(setAuthentication(true));
      dispatch(setUserStatus(data.user.status));
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      }
    },
  });
};

// ---- SOLICITAR RESTABLECER CONTRASEÑA ----
export const useResetPassword = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(resetPassword, {
    onSuccess: ({ data }) => {
      enqueueSnackbar(
        'Se ha enviado al correo registrado una contraseña temporal para iniciar sesión',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'PASSWORD_RESET',
          isActive: false,
        }),
      );
    },
  });
};
