import { batch, useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  getAggregationPermissions,
  getUser,
  requestUpdate,
  sendInbodyResult,
} from 'network/services/user.service';

import {
  setClosingDates,
  setInbodyScheduleTimes,
} from 'redux/slices/client.slice';

// ---- TRAE LA INFORMACIÓN DEL PARTNER ----
export const useUser = () => {
  const dispatch = useDispatch();

  return useQuery('GET_USER', getUser, {
    onSuccess: ({ data: { inbodyScheduleTimes = [], closingDates = [] } }) => {
      const mappedTimes = inbodyScheduleTimes.map((item, index) => ({
        id: `open-time-${index}`,
        data: { ...item },
      }));
      const mappedDates = closingDates.map((item, index) => ({
        id: `close-date-${index}`,
        data: { startDate: item.startDate, endDate: item.endDate },
      }));
      batch(() => {
        dispatch(setInbodyScheduleTimes(mappedTimes));
        dispatch(setClosingDates(mappedDates));
      });
    },
  });
};

// ---- ACTUALIZA LA INFORMACIÓN DEL PARTNER -----
export const useUpdate = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_USER');
      enqueueSnackbar('Se actualizó la información con éxito', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo actualizar la información. Intenta más tarde',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

// ---- ENVÍA EL RESULTADO INBODY POR EMAIL ----
export const useSendResult = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(sendInbodyResult, {
    onSuccess: () => {
      enqueueSnackbar(
        'Se envió correctamente la hoja de progreso por correo electrónico',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- TRAE LOS PERMISOS PARA LAS AGREGACIONES (FITPASS) ----
export const useAggregationPermissions = () => {
  return useQuery('GET_AGGREGATION_PERMISSIONS', getAggregationPermissions);
};
