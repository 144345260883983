import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import Button from '../../../Button/Button.component';

import {
  setCountryCodePlaceholder,
  setEmailPlaceholder,
  setPhoneNumberPlaceholder,
} from 'redux/slices/client.slice';

import { useVerification } from 'hooks/client.hook';

import { useStyles } from './ClientVerificationForm.styles';
import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

const ClientVerificationForm = () => {
  const [type, setType] = useState('email');

  const { mutate, isLoading } = useVerification();

  const { control, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const classes = useStyles();

  const label = {
    email: 'e-mail',
    phoneNumber: 'teléfono',
  }[type];

  const onChangeTypeHandler = event => {
    setType(event.target.value);
  };

  const onSubmitHandler = data => {
    const { email, phoneNumber, countryCode } = data;
    if (type === 'email') {
      mutate({ email });
      dispatch(setEmailPlaceholder(email));
    }
    if (type === 'phoneNumber') {
      const formatted = `+${countryCode}${phoneNumber}`;
      mutate({ phoneNumber, countryCode });
      batch(() => {
        dispatch(setPhoneNumberPlaceholder(phoneNumber));
        dispatch(setCountryCodePlaceholder(countryCode));
      });
    }
  };

  return (
    <>
      <Typography>
        Por favor ingresa el {label} del cliente que desea agregar
      </Typography>
      <Box pt={2}>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="verificationType"
            value={type}
            onChange={onChangeTypeHandler}
          >
            <Grid container>
              <Grid item xs={6} className={classes.radioContainer}>
                <FormControlLabel
                  label="Email"
                  value="email"
                  control={<Radio color="primary" />}
                />
              </Grid>
              <Grid item xs={6} className={classes.radioContainer}>
                <FormControlLabel
                  label="Teléfono"
                  value="phoneNumber"
                  control={<Radio color="primary" />}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit(onSubmitHandler)}>
        {type === 'email' && (
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Escribe un correo válido',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                label="Email"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Escribe el email'}
              />
            )}
          />
        )}
        {type === 'phoneNumber' && (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <WellnubCountryCodeInput
                label="Región"
                control={control}
                defaultValue="52"
                name="countryCode"
                rules={{ required: 'Este campo es requerido' }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <WellnubNumberInput
                fullWidth
                variant="outlined"
                label="Celular *"
                defaultValue=""
                name="phoneNumber"
                control={control}
                rules={{
                  required: 'Este campo es obligatorio',
                  pattern: {
                    value: /[0-9]{10}/,
                    message: 'Escribe un número válido',
                  },
                }}
                format="## #### ####"
                helperText="Celular de contacto a 10 dígitos"
              />
            </Grid>
          </Grid>
          // <Controller
          //   control={control}
          //   defaultValue=""
          //   name="phoneNumber"
          //   rules={{
          //     validate: value => validatePhoneNumber(value),
          //   }}
          //   render={({ field: { value, onChange }, fieldState: { error } }) => (
          //     <MUIPhoneInput
          //       value={value}
          //       fullWidth
          //       variant="outlined"
          //       error={!!error}
          //       label="Celular *"
          //       defaultCountry="mx"
          //       onChange={onChange}
          //       helperText={error?.message ?? 'Escribe el número de teléfono'}
          //       onlyCountries={['mx', 'us']}
          //     />
          //   )}
          // />
        )}
        <Box pt={2} textAlign="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Confirmar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ClientVerificationForm;
