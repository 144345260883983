import { useDispatch, useSelector } from 'react-redux';

import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import {
  Paper,
  Typography,
  Link,
  Box,
  useMediaQuery,
  Grid,
} from '@material-ui/core';

import Button from '../../Button/Button.component';

import { useUnlink } from 'hooks/client.hook';

import { setClientData } from 'redux/slices/client.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { useStyles } from './ClientCard.styles';

const ClientCard = ({ client }) => {
  const { mutate, isLoading } = useUnlink();

  const { status } = useSelector(state => state.user);

  const { url } = useRouteMatch();

  const dispatch = useDispatch();

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const onAddPassClickHandler = activeClient => event => {
    event.preventDefault();
    dispatch(setClientData(activeClient));
    dispatch(
      setModalStatus({
        name: 'ADD_INBODY_PASSES',
        isActive: true,
      }),
    );
  };

  const onUnlinkClickHandler = id => event => {
    event.preventDefault();
    mutate(id);
  };

  return (
    <Paper elevation={3} className={classes.paper} component="article">
      <Link to={`${url}/${client._id}`} underline="none" component={RouterLink}>
        <Typography className={classes.name}>
          {client.name} {client.lastName}
        </Typography>
      </Link>
      <Box py={isExtraSmallSize ? 1 : 2}>
        <Typography variant="body2" className={classes.passes}>
          Pases asignados: {client.inbodyPasses}
        </Typography>
      </Box>
      <Grid container component="footer" className={classes.center}>
        <Grid item sm={12} xs={6}>
          <Button
            variant="text"
            disabled={status === 'inactive'}
            className={`${classes.button} ${classes.primary}`}
            onClick={onAddPassClickHandler(client)}
          >
            Asignar pase
          </Button>
        </Grid>
        <Grid item sm={12} xs={6}>
          <Button
            variant="text"
            onClick={onUnlinkClickHandler(client.id)}
            loading={isLoading}
            className={`${classes.button} ${classes.secondary}`}
          >
            Desvincular
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientCard;
