import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';

import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

import { Box, Grid, TextField, Typography } from '@material-ui/core';

import { useAgregation, useLinkClient } from 'hooks/client.hook';

import {
  setCountryCodePlaceholder,
  setEmailPlaceholder,
  setPhoneNumberPlaceholder,
} from 'redux/slices/client.slice';

import Button from '../../../Button/Button.component';

import { useStyles } from './ClientAggregationForm.styles';

const ClientAggregationForm = () => {
  const { data, isAlreadyRegistered, placeholder } = useSelector(
    state => state.client,
  );

  const { mutate, isLoading } = useAgregation();
  const { mutate: linkClient, isLoading: isLinking } = useLinkClient();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: data?.name ?? '',
      lastName: data?.lastName ?? '',
      phoneNumber: isAlreadyRegistered
        ? data?.phoneNumber ?? placeholder?.phoneNumber
        : placeholder?.phoneNumber ?? '',
      email: isAlreadyRegistered ? data?.email ?? '' : placeholder?.email ?? '',
      countryCode: isAlreadyRegistered
        ? data?.countryCode ?? placeholder?.countryCode
        : placeholder?.countryCode ?? '',
    },
  });

  const dispatch = useDispatch();

  const classes = useStyles();

  const onSubmitHandler = dataForm => {
    const { phoneNumber, countryCode, ...restData } = dataForm;
    if (isAlreadyRegistered) {
      linkClient(data._id);
    } else {
      mutate({ ...restData, phoneNumber, countryCode });
    }
  };

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(setEmailPlaceholder(''));
        dispatch(setPhoneNumberPlaceholder(''));
        dispatch(setCountryCodePlaceholder(''));
      });
    };
  }, [dispatch]);

  return (
    <>
      <Typography>
        {isAlreadyRegistered
          ? 'Estos son los datos asociados al email. ¿Deseas agregar este cliente?'
          : 'Escribe los datos para agregar un nuevo cliente'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
        <Grid container spacing={4}>
          {/* --- Nombre --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'Este campo es requerido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered}
                  variant="outlined"
                  type="text"
                  label="Nombre"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el nombre'}
                />
              )}
            />
          </Grid>
          {/* --- Apellido --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: 'Este campo es requerido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered}
                  variant="outlined"
                  type="text"
                  label="Apellido"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el apellido'}
                />
              )}
            />
          </Grid>
          {/* --- Correo electronico --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue={!isAlreadyRegistered ? placeholder.email : ''}
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Escribe un correo válido',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled={isAlreadyRegistered || Boolean(placeholder.email)}
                  variant="outlined"
                  type="email"
                  label="Email"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe el email'}
                />
              )}
            />
          </Grid>
          {/* --- Telefono --- */}
          <Grid item md={6} sm={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <WellnubCountryCodeInput
                  label="Región"
                  name="countryCode"
                  control={control}
                  disabled={
                    isAlreadyRegistered || Boolean(placeholder.countryCode)
                  }
                  rules={{ required: 'Este campo es requerido' }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <WellnubNumberInput
                  fullWidth
                  variant="outlined"
                  label="Celular *"
                  disabled={
                    isAlreadyRegistered || Boolean(placeholder.phoneNumber)
                  }
                  defaultValue={
                    isAlreadyRegistered
                      ? data?.phoneNumber ?? placeholder?.phoneNumber
                      : placeholder?.phoneNumber ?? ''
                  }
                  name="phoneNumber"
                  control={control}
                  rules={{
                    pattern: {
                      value: /[0-9]{10}/,
                      message: 'Escribe un número válido',
                    },
                  }}
                  format="## #### ####"
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  defaultValue={!isAlreadyRegistered ? placeholder.phoneNumber : ''}*/}
            {/*  name="phoneNumber"*/}
            {/*  rules={{*/}
            {/*    validate: value => validatePhoneNumber(value),*/}
            {/*  }}*/}
            {/*  render={({*/}
            {/*    field: { value, onChange },*/}
            {/*    fieldState: { error },*/}
            {/*  }) => (*/}
            {/*    <MUIPhoneInput*/}
            {/*      value={value}*/}
            {/*      fullWidth*/}
            {/*      variant="outlined"*/}
            {/*      error={!!error}*/}
            {/*      label="Celular *"*/}
            {/*      defaultCountry="mx"*/}
            {/*      onChange={onChange}*/}
            {/*      helperText={*/}
            {/*        error?.message ?? 'Escribe el celular a 10 dígitos'*/}
            {/*      }*/}
            {/*      onlyCountries={['mx', 'us']}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
          </Grid>
        </Grid>
        <Box
          pt={2}
          width="100%"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading || isLinking}
          >
            Confirmar
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ClientAggregationForm;
