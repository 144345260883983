import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  cardNumber: {
    paddingLeft: theme.spacing(1),
  },
  cardLogo: {
    maxWidth: 45,
  },
}));
