import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addCard: {
    isVisible: false,
  },
  checkout: {
    isVisible: false,
  },
  signIn: {
    isVisible: false,
  },
};

export const drawersSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    setVisibility: (state, action) => {
      state[action.payload.key].isVisible = action.payload.status;
    },
  },
});

export const { setVisibility } = drawersSlice.actions;

export const drawersReducer = drawersSlice.reducer;
