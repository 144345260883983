import { useLocation, Link, useRouteMatch } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { useStyles } from './PaymentsNavigation.styles';

const PaymentsNavigation = () => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('inbody-passes')) return 'inbody-passes';
    if (pathname.includes('methods')) return 'methods';
    return 'inbody-passes';
  };

  return (
    <Tabs
      orientation="vertical"
      value={tabValueHandler()}
      variant="scrollable"
      className={classes.tabs}
      indicatorColor="primary"
    >
      <Tab
        component={Link}
        to={`${url}/inbody-passes`}
        label="Pases Inbody"
        value="inbody-passes"
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        component={Link}
        to={`${url}/methods`}
        label="Formas de pago"
        value="methods"
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default PaymentsNavigation;
