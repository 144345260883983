import { wellnub } from '../wellnub.api';

export const getUser = () => wellnub.get('/v1/partner/inbodyLocation');

export const requestUpdate = data =>
  wellnub.patch('/v1/partner/inbodyLocation', data);

export const sendInbodyResult = id =>
  wellnub.post(`/v1/partner/inbodyResult/emailResult/${id}`);

export const getAggregationPermissions = async () => {
  const { data } = await wellnub.get('/v1/partner/user/permissions');
  return data;
};
