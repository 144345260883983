import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

import {
  Box,
  CardMedia,
  Drawer,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../Button/Button.component';

import amex from 'assets/icons/card-amex.svg';
import visa from 'assets/icons/card-visa.svg';
import master from 'assets/icons/card-master.svg';

import { useDrawer } from 'hooks/drawers.hook';
import { useBuyBundles } from 'hooks/payments.hook';

import { DRAWERS } from 'utils/constants';
import { currencyFormatter } from 'utils/helpers';

import { useStyles } from './CheckoutDrawer.styles';
import conekta from 'assets/images/conekta.png';

const CheckoutDrawer = () => {
  const [isDefault, setDefault] = useState(true);
  const [cardList, setCardList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');

  const { cards, order } = useSelector(state => state.payments);

  const { mutate: buyBundle, isLoading } = useBuyBundles();

  const { visibility, handleClose } = useDrawer(DRAWERS.checkout);
  const { handleOpen } = useDrawer(DRAWERS.addCard);

  const classes = useStyles();

  const hasPaymentMethod = !isEmpty(cards);

  const onNewCardClickHandler = event => {
    event.preventDefault();
    handleOpen();
  };

  const onCardChangeHandler = event => {
    event.preventDefault();
    setSelectedCardId(event.target.value);
  };

  const onPaymentChangeHandler = event => {
    event.preventDefault();
    setDefault(false);
  };

  const onConfirmClickHandler = event => {
    event.preventDefault();
    buyBundle({
      cardId: selectedCardId,
      storeProductId: order.id,
    });
  };

  useEffect(() => {
    if (typeof cards !== 'undefined') {
      const defaultCard = cards.find(card => card.default);
      const mapped = cards.map(card => ({
        ...card,
        logo: {
          mastercard: master,
          visa: visa,
          american_express: amex,
        }[card.brand],
      }));
      setCardList(mapped);
      setSelectedCardId(defaultCard?.id ?? '');
    }
  }, [cards]);

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Realiza tu compra
        </Typography>
      </Box>
      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.subtitle}>
          Detalles de la compra
        </Typography>
        <Typography>
          Al adquirir pases, podrás asignarlos a tus pacientes cuando así lo
          desees. Los pases que adquieras no tienen fecha de caducidad.
        </Typography>
      </Box>
      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.subtitle}>
          {order.name}
        </Typography>
        <Typography className={classes.center}>
          <NumberFormat
            value={order.total * 100}
            displayType="text"
            format={currencyFormatter}
          />
        </Typography>
      </Box>
      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.subtitle}>
          Forma de pago
        </Typography>
        {hasPaymentMethod &&
          (isDefault ? (
            <>
              <RadioGroup
                name="payments"
                value={selectedCardId}
                onChange={onCardChangeHandler}
              >
                {cardList
                  .filter(card => card.default)
                  .map(card => (
                    <Box display="flex" alignItems="center" key={card.id}>
                      <Radio value={card.id} color="primary" />
                      <CardMedia
                        component="img"
                        image={card.logo}
                        className={classes.cardLogo}
                      />
                      <Typography
                        variant="body2"
                        className={classes.cardNumber}
                      >
                        &#8226;&#8226;&#8226;&#8226; {card.last4}
                      </Typography>
                    </Box>
                  ))}
              </RadioGroup>
              <Box pt={2}>
                <Typography
                  variant="body2"
                  className={classes.asLink}
                  onClick={onPaymentChangeHandler}
                >
                  Usar otra forma de pago
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <RadioGroup
                name="payments"
                value={selectedCardId}
                onChange={onCardChangeHandler}
              >
                {cardList.map(card => (
                  <Box display="flex" alignItems="center" key={card.id}>
                    <Radio value={card.id} color="primary" />
                    <CardMedia
                      component="img"
                      image={card.logo}
                      className={classes.cardLogo}
                    />
                    <Typography variant="body2" className={classes.cardNumber}>
                      &#8226;&#8226;&#8226;&#8226; {card.last4}
                    </Typography>
                  </Box>
                ))}
              </RadioGroup>
              <Box pt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  endIcon={<AddIcon />}
                  onClick={onNewCardClickHandler}
                >
                  Agregar forma de pago
                </Button>
              </Box>
            </>
          ))}
        {!hasPaymentMethod && (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            endIcon={<AddIcon />}
            onClick={onNewCardClickHandler}
          >
            Agregar forma de pago
          </Button>
        )}
        <Box pt={2}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={!hasPaymentMethod || isLoading}
            onClick={onConfirmClickHandler}
            loading={isLoading}
          >
            Confirma tu orden
          </Button>
        </Box>
        <Box pt={2}>
          <Typography variant="body2">
            Datos protegidos encriptados y pagos seguros con Conekta&reg;
          </Typography>
          <CardMedia
            component="img"
            image={conekta}
            className={classes.conekta}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default CheckoutDrawer;
