import { useSelector } from 'react-redux';

import { Typography, Box } from '@material-ui/core';

import Button from '../../Button/Button.component';

import { useInbodyPasses } from 'hooks/client.hook';

import { useStyles } from './InbodyPassForm.styles';

const InbodyPassForm = () => {
  const { mutate, isLoading } = useInbodyPasses();

  const {
    data: { _id },
  } = useSelector(state => state.client);

  const classes = useStyles();

  const onConfirmClickHandler = event => {
    event.preventDefault();
    mutate(_id);
  };

  return (
    <>
      <Typography className={classes.paragraph}>
        ¿Confirmas que deseas agregar 1 pase de escaneo corporal a tu cliente?
      </Typography>
      <Typography className={classes.paragraph}>
        Al confirmar, tomaremos un pase de tu cuenta de pases de escaneo
        corporal disponibles y se lo asignaremos a este cliente. Esta acción no
        podrá ser reversible.
      </Typography>
      <Typography className={classes.paragraph}>
        Tendrás hasta 48 horas a partir de este momento para realizarle el
        escaneo a tu cliente.
      </Typography>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={onConfirmClickHandler}
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default InbodyPassForm;
